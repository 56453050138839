export const typography = {
  letterSpacing: {
    'letterSpacing-wider': '0.05rem',
  },
  lineHeights: {
    'lineHeight-3': '1rem',
    'lineHeight-4': '1.125rem',
    'lineHeight-5': '1.25rem',
    'lineHeight-6': '1.6rem',
    'lineHeight-7': '1.75rem',
    'lineHeight-8': '2rem',
    'lineHeight-9': '2.25rem',
    'lineHeight-10': '2.5rem',
    'lineHeight-11': '3rem',
    'lineHeight-12': '3.75rem',
  },
  fontWeights: {
    book: 375,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    black: 900,
  },
  fonts: {
    body: 'Biennale',
    heading: 'GuyotPress',
    grace: 'Covered By Your Grace',
  },
  fontSizes: {
    'text-xs': '0.75rem',
    'text-sm': '0.875rem',
    'text-md': '1rem',
    'text-lg': '1.125rem',
    'text-xl': '1.25rem',
    'text-2xl': '1.5rem',
    'text-3xl': '1.875rem',
    'text-4xl': '2.25rem',
    'text-5xl': '3rem',
    'text-6xl': '3.75rem',
    'component-heading-large/xs': '0.875rem',
    'component-heading-large/sm': '1rem',
    'component-heading-large/lg': '1.875rem',
    'component-heading-large/xl': '2.25rem',
    'component-heading-large/2xl': '3rem',
    'component-heading-large/3xl': '3.75rem',
    'component-heading-large/4xl': '4.5rem',
    'component-heading-large/5xl': '5.5rem',
    'component-heading-large/6xl': '6.75rem',
    'component-heading-small/xs': '0.875rem',
    'component-heading-small/xm': '1rem',
    'component-heading-small/md': '1.25rem',
    'component-heading-small/lg': '1.5rem',
    'component-heading-small/xl': '1.875rem',
    'component-heading-small/2xl': '2.25rem',
    'component-heading-small/3xl': '3rem',
    'component-heading-small/4xl': '3.75rem',
    'component-heading-small/5xl': '4.5rem',
    'component-heading-small/6xl': '5.5rem',
    'component-paragraph-headline/xs': '0.75rem',
    'component-paragraph-headline/sm': '0.875rem',
    'component-paragraph-headline/md': '1rem',
    'component-paragraph-headline/lg': '1.125rem',
    'component-paragraph-headline/xl': '1.25rem',
    'component-paragraph-headline/2xl': '1.5rem',
    'component-paragraph-headline/3xl': '2rem',
    'component-paragraph-headline/4xl': '2.25rem',
    'component-paragraph-headline/5xl': '3rem',
    'component-paragraph-headline/6xl': '3.75rem',
    'component-text/xs': '0.75rem',
    'component-text/sm': '0.875rem',
    'component-text/md': '1rem',
    'component-text/lg': '1.125rem',
    'component-text/xl': '1.25rem',
    'component-text/2xl': '1.5rem',
    'component-text/3xl': '1.875rem',
    'component-text/4xl': '2.25rem',
    'component-text/5xl': '3rem',
    'component-text/6xl': '3.75rem',
  },
};

export default typography;

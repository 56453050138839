import { Flex } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import { navbarItems } from '@/utils/models';

import { isMenuActive } from './Navbar.utils';
import { NavbarItem } from './NavbarItem';
import { NavbarItemWithDropdown } from './NavbarItemWithDropdown';

export const Navbar = () => {
  const { home: _home, ...otherItems } = navbarItems;
  const { route } = useRouter();

  return (
    <Flex as="nav" width="100%" alignItems="center">
      <Flex as="ul" width="100%" alignItems="center">
        <Flex as="li" justifyContent="space-between" position="relative" margin="0 auto">
          <Flex as="ul">
            {Object.values(otherItems).map((otherItem) => {
              const { labelTransKey, path, isDropdown, subItems, isMobileButton, isDropdownLink, dropdownArrowLeft } =
                otherItem;
              const isActive = isMenuActive(route, path);

              if (!isMobileButton) {
                if (isDropdown) {
                  return (
                    <NavbarItemWithDropdown
                      isActive={isActive}
                      isLink={!!isDropdownLink}
                      key={labelTransKey}
                      labelTransKey={labelTransKey}
                      path={path}
                      subItems={subItems}
                      dropdownArrowLeft={dropdownArrowLeft}
                    />
                  );
                } else {
                  return (
                    <NavbarItem isActive={isActive} key={labelTransKey} labelTransKey={labelTransKey} path={path} />
                  );
                }
              }
            })}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

import { Global } from '@emotion/react';

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Biennale';
        font-style: normal;
        font-weight: 375;
        font-display: swap;
        src: url('/assets/fonts/Biennale-light.woff2') format('woff2'),
             url('/assets/fonts/Biennale-light.woff') format('woff');
      }
      
      @font-face {
        font-family: 'Biennale';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/assets/fonts/Biennale-regular.woff2') format('woff2'),
             url('/assets/fonts/Biennale-regular.woff') format('woff');
      }
      
      @font-face {
        font-family: 'Biennale';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('/assets/fonts/Biennale-medium.woff2') format('woff2'),
             url('/assets/fonts/Biennale-medium.woff') format('woff');
      }
      
      @font-face {
        font-family: 'Biennale';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url('/assets/fonts/Biennale-bold.woff2') format('woff2'),
             url('/assets/fonts/Biennale-bold.woff') format('woff');
      }
      
      @font-face {
        font-family: 'Biennale';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('/assets/fonts/Biennale-heavy.woff2') format('woff2'),
             url('/assets/fonts/Biennale-heavy.woff') format('woff');
      }

      @font-face {
        font-family: 'Biennale';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url('/assets/fonts/Biennale-heavy.woff2') format('woff2'),
             url('/assets/fonts/Biennale-heavy.woff') format('woff');
      }

      @font-face {
        font-family: 'GuyotPress';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/assets/fonts/GuyotPress-Regular.woff2') format('woff2'), 
              url('/assets/fonts/GuyotPress-Regular.woff') format('woff');
      }
      
      @font-face {
        font-family: 'Covered By Your Grace';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('/assets/fonts/CoveredByYourGrace-Regular.woff2') format('woff2'),
             url('/assets/fonts/CoveredByYourGrace-Regular.woff') format('woff');
      }
    `}
  />
);

import DevelopmentIcon from 'images/icons/development.svg';
import MagicIcon from 'images/icons/magic.svg';
import NoCodeIcon from 'images/icons/nocode.svg';
import PenIcon from 'images/icons/pen.svg';
import UserIcon from 'images/icons/user.svg';

import { GeneralPath, IndustriesPath, ServicesPath } from './page-paths';

type MainNavbarItemNames =
  | 'home'
  | 'services'
  | 'industries'
  | 'caseStudies'
  | 'resources'
  | 'aboutUs'
  | 'careers'
  | 'letsTalk';

export type NavbarItem = {
  path: string;
  labelTransKey: string;
  descTransKey?: string;
  icon?: any;
  color?: string;
  background?: string;
  isDropdown?: boolean;
  subItems?: NavbarItem[];
  isMobileButton?: boolean;
  isDropdownLink?: boolean;
  dropdownArrowLeft?: string;
};

export type NavbarItems = {
  [key in MainNavbarItemNames]: NavbarItem;
};

export const navbarItems: NavbarItems = {
  home: {
    path: GeneralPath.HOME,
    labelTransKey: 'header.home',
  },
  services: {
    path: GeneralPath.SERVICES,
    labelTransKey: 'header.services',
    isDropdown: true,
    dropdownArrowLeft: '64px',
    subItems: [
      {
        path: ServicesPath.PRODUCT_DISCOVERY,
        labelTransKey: 'header.productDiscoveryLabel',
        descTransKey: 'header.productDiscoveryDesc',
        icon: UserIcon,
        color: 'lime.700',
        background: 'lime.100',
      },
      {
        path: ServicesPath.DESIGN,
        labelTransKey: 'header.designLabel',
        descTransKey: 'header.designDesc',
        icon: PenIcon,
        color: 'beige.300',
        background: 'beige.50',
      },
      {
        path: ServicesPath.NO_CODE,
        labelTransKey: 'header.nocodeLabel',
        descTransKey: 'header.nocodeDesc',
        icon: NoCodeIcon,
        color: 'purple.500',
        background: 'purple.50',
      },
      {
        path: ServicesPath.DEVELOPMENT,
        labelTransKey: 'header.developmentLabel',
        descTransKey: 'header.developmentDesc',
        icon: DevelopmentIcon,
        color: 'red.400',
        background: 'red.50',
      },
      {
        path: ServicesPath.MVP_BUILDING,
        labelTransKey: 'header.mvpLabel',
        descTransKey: 'header.mvpDesc',
        icon: MagicIcon,
        color: 'violet.500',
        background: 'violet.50',
      },
      {
        path: GeneralPath.CONTACT,
        labelTransKey: 'header.contact',
        isMobileButton: true,
      },
    ],
  },
  industries: {
    path: IndustriesPath.HOME,
    labelTransKey: 'header.industries',
    isDropdown: true,
    isDropdownLink: true,
    dropdownArrowLeft: '200px',
    subItems: [
      {
        path: IndustriesPath.HEALTHCARE,
        labelTransKey: 'header.healthcareLabel',
        descTransKey: 'header.healthcareDesc',
        icon: UserIcon,
        color: 'lime.700',
        background: 'lime.100',
      },
      {
        path: IndustriesPath.EDTECH,
        labelTransKey: 'header.edtechLabel',
        descTransKey: 'header.edtechDesc',
        icon: PenIcon,
        color: 'beige.300',
        background: 'beige.50',
      },
      {
        path: IndustriesPath.AI,
        labelTransKey: 'header.aiLabel',
        descTransKey: 'header.aiDesc',
        icon: DevelopmentIcon,
        color: 'red.400',
        background: 'red.50',
      },
      {
        path: IndustriesPath.FINTECH,
        labelTransKey: 'header.fintechLabel',
        descTransKey: 'header.fintechDesc',
        icon: NoCodeIcon,
        color: 'purple.500',
        background: 'purple.50',
      },
      {
        path: IndustriesPath.TRAVEL,
        labelTransKey: 'header.travelLabel',
        descTransKey: 'header.travelDesc',
        icon: MagicIcon,
        color: 'violet.500',
        background: 'violet.50',
      },
      {
        path: GeneralPath.CONTACT,
        labelTransKey: 'header.contact',
        isMobileButton: true,
      },
    ],
  },
  caseStudies: {
    path: GeneralPath.CASE_STUDIES,
    labelTransKey: 'header.caseStudies',
  },
  resources: {
    path: GeneralPath.BLOG,
    labelTransKey: 'header.blog',
  },
  aboutUs: {
    path: GeneralPath.ABOUT_US,
    labelTransKey: 'header.aboutUs',
  },
  careers: {
    path: process.env.NEXT_PUBLIC_CAREERS_URL as string,
    labelTransKey: 'header.careers',
  },
  letsTalk: {
    path: GeneralPath.CONTACT,
    labelTransKey: 'header.contact',
    isMobileButton: true,
  },
};

import { useEffect, useRef } from 'react';

import { Flex, useMediaQuery, useTheme } from '@chakra-ui/react';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { CONTENT_MARGIN_X, HEADER_HEIGHT } from '@/utils/constants';
import { NavbarItems } from '@/utils/models';

import { NavbarItem as MobileMenuItem } from '../../Navbar';
import { ExpandableMobileMenuItem } from '../ExpandableMobileMenuItem';

interface MobileMenuProps {
  isMenuOpen: boolean;
  menuItems: Omit<NavbarItems, 'home'>;
  setIsMenuOpen: () => void;
}

export const MobileMenu = ({ isMenuOpen, menuItems, setIsMenuOpen }: MobileMenuProps) => {
  const theme = useTheme();
  const [isMobileNavbarWidth, isOrientationLandscape] = useMediaQuery([
    `(max-width: ${theme.breakpoints.md})`,
    '(orientation: landscape)',
  ]);

  const targetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isMenuOpen && targetRef.current) {
      disableBodyScroll(targetRef.current);
    } else if (targetRef.current) {
      enableBodyScroll(targetRef.current);
    }
    return () => clearAllBodyScrollLocks();
  }, [isMenuOpen]);

  return (
    <Flex
      ref={targetRef}
      flexDirection="column"
      position="fixed"
      p={CONTENT_MARGIN_X}
      top={HEADER_HEIGHT}
      right={0}
      bottom={0}
      left={0}
      border={0}
      backgroundColor="beige.50"
      transform={isMenuOpen ? 'translateX(0)' : 'translateX(100%)'}
      transition="all 0.2s linear, height 0ms"
      visibility={isMenuOpen ? 'visible' : 'hidden'}
      aria-hidden={!isMenuOpen}
      overflowY={isMobileNavbarWidth && isOrientationLandscape ? 'scroll' : 'unset'}
    >
      <Flex
        as="ul"
        flexDirection="column"
        gap={6}
        height={isOrientationLandscape ? 'auto' : '100%'}
        position="relative"
        listStyleType="none"
        justifyContent={'flex-end'}
      >
        {Object.values(menuItems).map((otherItem) => {
          const { labelTransKey, path, isDropdown, subItems, isMobileButton } = otherItem;
          if (isDropdown) {
            return (
              <ExpandableMobileMenuItem
                key={labelTransKey}
                labelTransKey={labelTransKey}
                subItems={subItems}
                path={path}
                setIsMenuOpen={setIsMenuOpen}
              />
            );
          } else {
            return (
              <MobileMenuItem
                key={labelTransKey + path}
                labelTransKey={labelTransKey}
                path={path}
                setIsMenuOpen={setIsMenuOpen}
                isMobileButton={isMobileButton}
                isMobile
                isActive={false}
              />
            );
          }
        })}
      </Flex>
    </Flex>
  );
};
